const testimonis = [
  {
    id: 1,
    name: "Krisviani",
    position: false,
    message:
      "Makasih sudah menyediakan tempat untuk mempermudah menyalurkan bantuan kepada orang yang membutuhkan, karena ga semua orang bisa turun langsung buat membantu Jadi adanya organisasi ini bener bener mempermudah buat orang yang ingin melakukan kebaikan tapi gak bisa turun langsung 👍🏻. Semoga terus bermanfaat buat semua orang ya",
    image: "krisviani.png",
  },
  {
    id: 2,
    name: "Ibu Yayuk",
    position: false,
    message:
      "Alhamdulillah dengan adanya komunitas organisasi swap yg bergerak dalam sosial sungguh luar biasa berdampak positif banget bagi kami Pondok Panti Lansia Mbah Putri Kendalrejo Blitar  dimana bisa sedikit meringankan beban buat kami dan sudah sangat terbantu .. semoga Swap tetap kompak ,solid, membawa keberkahan buat semuanya 🤲🏻🤲🏻🙏",
    image: "buyayuk.png",
  },
  {
    id: 3,
    name: "Dr. Vina",
    position: false,
    message:
      'Dikenalkan SWAP "Smart With A Purpose Project" dari dek Novi, dan Alhamdulillah apa yang kami donasikan benar-benar dapat tersalurkan dan amanah. Jadi barang-barang kita yang masih bisa terpakai bisa bermanfaat untuk orang lain daripada nganggur di lemari dan dihisab. Dan program donasi lainnya juga bisa banget tuh, daripada bingung 2.5% pendapatan kita mau dikemanakan, bisa banget dimanfaatkan untuk sesama.  Karena jujur, di sekitar kita ini masih banyak banget orang yang kekurangan, kalau bukan kita, siapa yang akan membantu mereka.',
    image: "dr-vina.png",
  },
  {
    id: 4,
    name: "Imelda Ndira Intan Pracilia",
    position: "Diajeng Persahabatan Kota Blitar tahun 2021",
    message:
      "Saya sangat bersyukur bisa menjadi bagian dari SWAP Blitar. Dari awal diberitahu tentang kesempatan bergabung, saya langsung merasa bahwa ini adalah komunitas yang benar-benar memiliki tujuan mulia untuk membantu sesama tanpa pamrih.  Setiap langkah dalam kegiatan SWAP, mulai dari penggalangan dana yang dilakukan secara maksimal, desain poster yang tidak asal-asalan, hingga perencanaan yang sangat matang sebelum dan selama acara berlangsung, menunjukkan profesionalisme dan dedikasi tim SWAP. Hal yang paling menginspirasi saya adalah perhatian yang diberikan SWAP terhadap dampak dan keberlanjutan dari bantuan yang diberikan kepada para penerima manfaat. Pengalaman bersama SWAP memberikan kesan mendalam bagi saya. Bahkan, saya sering membagikan momen-momen berharga ini melalui media sosial karena ingin mengabadikan pengalaman yang begitu berarti. Terima kasih banyak untuk tim SWAP yang luar biasa. Semoga tetap istiqomah dalam kebaikan dan terus memberikan dampak positif bagi banyak orang. Saya sangat berharap bisa berkontribusi lagi di kegiatan SWAP mendatang. Sehat selalu dan panjang umur perjuangan!",
    image: "imelda.jpg",
  },
  {
    id: 5,
    name: "Fajar Apriliansyah",
    position: "Wakil I Kangmas Kota Blitar tahun 2024",
    message:
      "Pengalaman pertama ikut SWAP menurut saya adalah pengalaman yang sangat sangat berkesan, karena dengan kegiatan bakti sosial di rumah kinasih kemarin, dengan teman teman yang lain membuat saya semakin bersyukur atas segala nikmat yang diberikan oleh Tuhan, dan kekurangan manusia pasti akan selalu ditemukan jika dilihat sebatas pandangan manusia saja, selalu ada hal baik seiring dengan hal yang kita anggap buruk, selalu ada alasan di balik semua ini. Alhamdulillah dengan kegiatan bersama swap kemarin banyak hal positif yang saya dapatkan khususnya untuk diri saya sendiri. Terimakasih SWAP Blitar!",
    image: "fajar.jpg",
  },
  {
    id: 6,
    name: "Sella Ardhisa R.",
    position: "Founder Herbalova",
    message:
      "Terima kasih Herbalova dan Swap atas kontribusi positifnya dan sangat inspiratif. Saya mewakili team Herbalova mengucapkan terimakasih banyak kepada swap dan sangat  terkesan  atas  kolaborasi Herbalova dan Swap. Kolaborasi yang tidak hanya memberikan dukungan materi, tapi juga membangun kesadaran dan kepedulian terhadap komunitas berkebutuhan khusus. Kolaborasi ini membuktikan bahwa kebaikan dan kepedulian dapat mengubah hidup orang banyak. Terima kasih atas dedikasi dan kontribusi positifnya. Semoga kegiatan seperti ini akan selalu berjalan dan berdampak untuk sesama.",
    image: "sella.jpg",
  },
];

export default testimonis;
