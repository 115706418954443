const partners = [
  {
    id: 1,
    name: "Palang Merah Indonesia",
    image: "pmi.png",
  },
  {
    id: 2,
    name: "MTSN 1 Blitar",
    image: "mtsn1-blitar.png",
  },
  {
    id: 3,
    name: "MI Miftahun Najah",
    image: "mi-miftahun-najah.png",
  },
  {
    id: 4,
    name: "Rumah Kinasih",
    image: "rumah_kinasih.png",
  },
  {
    id: 5,
    name: "Herbalova",
    image: "herbalova.png",
  },
];

export default partners;
